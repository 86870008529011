import { useState } from 'react';
import { ShoppingCartItem } from './components/ShoppingCartItem';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { CartItem, useCart } from './contexts/CartProvider';

export const ShoppingCart = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [removeCandidate, setRemoveCandidate] = useState<CartItem | null>();
  const { cartItems, removeItem } = useCart();

  const openDialog = (itemId: number) => {
    if (cartItems.length === 0) return;

    setRemoveCandidate(cartItems.find((item) => item.id === itemId));
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const confrimRemoveItem = (id: number) => {
    closeDialog();
    removeItem(id);
    setRemoveCandidate(null);
  };

  return (
    <>
      <Typography variant="h5" component={'h2'}>
        주문 대기 목록
      </Typography>

      {cartItems.length === 0 ? (
        <p>주문 대기 항목이 없습니다.</p>
      ) : (
        <>
          <Box sx={{ height: `calc(100vh - 12.6rem)`, overflow: 'scroll' }}>
            {cartItems.map((item, idx) => (
              <ShoppingCartItem key={idx} item={item} onRemoveClicked={(id) => openDialog(id)} />
            ))}
          </Box>
          <Box
            sx={{
              position: 'fixed',
              width: '100%',
              left: 0,
              bottom: '4.5rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button variant="contained" sx={{ width: '91%' }} onClick={() => {}}>
              주문하기
            </Button>
          </Box>
        </>
      )}

      {/* dialog for removing item */}
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'해당 항목을 삭제하시겠습니까?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {removeCandidate ? `${removeCandidate?.name}, ${removeCandidate?.qty}개` : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>취소</Button>
          <Button onClick={() => confrimRemoveItem(removeCandidate!.id)} autoFocus>
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
