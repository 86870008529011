import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

type ItemSearchBarProps = {
  setSearchKeyword: Dispatch<SetStateAction<string>>;
};

export const ItemSearchBar: FC<ItemSearchBarProps> = ({ setSearchKeyword }) => {
  const [category, setCategory] = useState<string>('all');

  const handleChange = (e: SelectChangeEvent<string>) => {
    setCategory(e.target.value as string);
    console.log('category:', e.target.value);
  };

  const onKeywordChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
  };

  return (
    <Stack direction={'row'} spacing={0.5}>
      <Select
        sx={{
          minWidth: '120px',
        }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={category}
        label="Category"
        onChange={handleChange}
      >
        <MenuItem value={'all'}>전체</MenuItem>
        <MenuItem value={'consumables'}>소모품</MenuItem>
        <MenuItem value={'parts'}>수리부품</MenuItem>
      </Select>
      <TextField
        fullWidth
        id="outlined-basic"
        label="키워드 검색"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={onKeywordChanged}
      />
    </Stack>
  );
};
