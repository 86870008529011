import axios, { AxiosError, AxiosInstance } from 'axios';
import { useAuth } from '../common/contexts/AuthProvider';
import { useMemo } from 'react';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const addInterceptor = (axiosInstance: AxiosInstance, logout: () => void) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (err: AxiosError) => {
      if (err.config && err.response && err.response.status === 401) {
        const refreshToken = localStorage.getItem('refreshToken');

        if (!refreshToken) {
          logout();
        }

        console.log(`request refresh token with ${refreshToken}`);

        try {
          // axiosInstance를 사용하면 계속 interceptor를 타기 떄문에 별도의 axios 객체를 사용
          const newTokens = await axios.post(
            `${BASE_URL}/auth/refresh`,
            { refreshToken },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          if (newTokens.status !== 200) {
            console.error('failed to post /auth/refresh');
            throw new Error('failed to post /auth/refresh');
          }

          localStorage.setItem('accessToken', newTokens.data.accessToken);
          localStorage.setItem('refreshToken', newTokens.data.refreshToken);

          err.config.headers.Authorization = `Bearer ${newTokens.data.accessToken}`;
          console.log('trying to request with new accessToken');
          return await axios.request(err.config);
        } catch (error) {
          console.error('failed to get refresh token.');
          logout();
          // throw new Error('failed to get refresh token.');
        }
      }

      //return Promise.reject(err);
    }
  );
};
// export const createAxiosInstance = (accessToken: string | null = null): AxiosInstance => {
//   const instance = axios.create({
//     baseURL: BASE_URL,
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });

//   if (accessToken !== null) {
//     instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
//   }

//   return instance;
// };

export const createPublicAxios = () => {
  return axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const useAxios = (): AxiosInstance => {
  const { accessToken, logout } = useAuth();

  const axiosInstance = useMemo(
    () =>
      axios.create({
        baseURL: BASE_URL,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    []
  );

  if (!accessToken) {
    logout();
  }

  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  addInterceptor(axiosInstance, logout);

  return axiosInstance;
};
