import { Header } from '../../../common/components/Header';
import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';
import { CartProvider } from '../contexts/CartProvider';
import { BottomNav } from './BottomNav';

export const MainLayout = () => {
  return (
    <CartProvider>
      <Header />
      <Container sx={{ pt: '4rem', mb: '4rem' }}>
        <Outlet />
      </Container>
      <BottomNav />
    </CartProvider>
  );
};
