import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useAxios } from '../../hooks';
import { useCart } from './contexts/CartProvider';

type ProductType = {
  id: string;
  name: string;
  productType: string;
  partNo: string;
  description: string;
  images: string[];
};

export const ProductDetail: FC = () => {
  const axios = useAxios();
  const params = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState<ProductType | null>(null);
  const [orderCount, setOrderCount] = useState<number>(1);
  const [modalOpen, setModalOpen] = React.useState(false);
  const { addItem } = useCart();

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const goToProductsPage = () => navigate('/products');
  const goToShoppingCartPage = () => navigate('/shopping-cart');

  useEffect(() => {
    const getProductDetail = async () => {
      const product = (await axios.get(`/products/${params['id']}`)).data;
      setProduct(product);
    };

    getProductDetail();
  }, [axios, params]);

  const handleOrderCount = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    /**referred https://velog.io/@yyeonggg/%EC%97%B4%EB%B0%9B%EB%8A%94-MUI-%EC%A0%95%EB%A6%AC */
    setOrderCount(+e.target.value.replace(/\D/g, ''));
    // if (e.target.value === '') {
    //   // setOrderCount(1);
    // } else {
    //   /**referred https://velog.io/@yyeonggg/%EC%97%B4%EB%B0%9B%EB%8A%94-MUI-%EC%A0%95%EB%A6%AC */
    //   setOrderCount(+e.target.value.replace(/\D/g, ''));
    // }
  }, []);

  const addToCart = () => {
    if (!product) {
      return;
    }

    addItem({
      name: product.name,
      qty: orderCount,
      orderType: 'search',
    });

    openModal();
  };

  const raiseOrderCount = () => {
    if (orderCount >= 99) return;

    setOrderCount((prev) => prev + 1);
  };

  const reduceOrderCount = () => {
    if (orderCount <= 1) return;

    setOrderCount((prev) => prev - 1);
  };

  return (
    <>
      <Box sx={{ height: `calc(100vh - 11rem)`, overflow: 'scroll' }}>
        <IconButton color="primary" aria-label="navigate back" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" component={'h2'} align="center" fontWeight={'bold'}>
          {product?.name}
        </Typography>
        <Box display={'flex'} justifyContent={'center'}>
          <img
            src={
              'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e?w=300&h=200&fit=crop&auto=format'
            }
            alt="ProductImage"
          />
        </Box>
        {/* 수량 선택 */}
        <Box display={'flex'} justifyContent={'center'} mt={3}>
          <TextField
            required
            error={orderCount <= 0}
            helperText={
              orderCount <= 0
                ? '주문 수량은 한개 이상이어야 합니다'
                : '주문수량을 입력하세요 (최대 99개)'
            }
            label="주문수량"
            type="text"
            size="small"
            margin="none"
            value={orderCount}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              maxLength: 2,
              style: {
                textAlign: 'left',
              },
            }}
            onChange={handleOrderCount}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <IconButton
            color="primary"
            size="large"
            disableRipple
            aria-label="AddItemCount"
            onClick={raiseOrderCount}
          >
            <AddBoxIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            color="primary"
            size="large"
            disableRipple
            aria-label="SubtractItemCount"
            onClick={reduceOrderCount}
          >
            <IndeterminateCheckBoxIcon fontSize="inherit" />
          </IconButton>
        </Box>
        <Typography variant="body1">{product?.productType}</Typography>
        <Typography variant="body1">{product?.description}</Typography>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          width: '100%',
          left: 0,
          bottom: '4.5rem',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="contained"
          sx={{ width: '91%' }}
          disabled={orderCount <= 0}
          onClick={addToCart}
        >
          주문대기목록에 추가
        </Button>
      </Box>

      {/* dialog for confirming order*/}
      <Dialog
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">제품이 주문대기목록에 추가되었습니다.</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            항목 표시
          </DialogContentText> */}
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Button variant="outlined" onClick={goToProductsPage}>
            다른 제품 계속 주문
          </Button>
          <Button variant="outlined" onClick={goToShoppingCartPage} autoFocus>
            주문대기목록 이동
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
