import { FC, FormEvent, SyntheticEvent, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const CustomTabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const FindCredential = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleFindId = (e: FormEvent<HTMLFormElement>) => {
    navigate('/login');
  }

  const handleFindPassword= (e: FormEvent<HTMLFormElement>) => {
    navigate('/login');
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h5" fontWeight={'400'} marginY={'1rem'} align="center">
        아이디 / 비밀번호 찾기
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} centered aria-label="basic tabs example">
          <Tab label="아이디(이메일) 찾기" {...a11yProps(0)} />
          <Tab label="비밀번호 찾기" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box component="form" onSubmit={handleFindId} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="이름"
            name="name"
            autoComplete="name"
            helperText="가입시 사용한 이름을 입력하세요"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="phone"
            label="전화번호"
            type="number"
            id="phone"
            autoComplete="phone"
            helperText="가입시 사용한 전화번호를 입력하세요"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            아이디(이메일) 찾기
          </Button>
        </Box>
      </CustomTabPanel>

      {/* 비밀번호 찾기 */}
      <CustomTabPanel value={value} index={1}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="아이디(이메일)"
            name="email"
            autoComplete="email"
            autoFocus
          />
        <Box component="form" onSubmit={handleFindPassword} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="이름"
            name="name"
            autoComplete="name"
            helperText="가입시 사용한 이름을 입력하세요"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="phone"
            label="전화번호"
            type="number"
            id="phone"
            autoComplete="phone"
            helperText="가입시 사용한 전화번호를 입력하세요"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            비밀번호 찾기
          </Button>
        </Box>
      </CustomTabPanel>
    </Box>
  );
};
