import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { OrderHome } from './pages/order/OrderHome';
import { Products } from './pages/order/Products';
import { ProductDetail } from './pages/order/ProductDetail';
import { NoMatch } from './pages/NoMatch';
import { Login } from './pages/authentication/Login';
import { Signup } from './pages/authentication/Signup';
import { createTheme, CssBaseline } from '@mui/material';
import { AuthProvider } from './common/contexts/AuthProvider';
import { MainLayout } from './pages/order/layouts/MainLayout';
import { ShoppingCart } from './pages/order/ShoppingCart';
import { ManualOrder } from './pages/order/ManualOrder';
import { OrderHistory } from './pages/order/OrderHistory';
import { Retailers } from './pages/retailer/Retailers';
import { FindCredential } from './pages/authentication/FindCredential';
import { ThemeProvider } from '@emotion/react';
import { blue, indigo, purple, red } from '@mui/material/colors';
import { ProtectedRoute } from './common/contexts/ProtectedRoute';

const theme = createTheme({
  palette: {
    primary: {
      main: indigo[500],
    }
  },
  typography: {
    fontFamily: 'S-CoreDream-4Regular'  // 기본 폰트
  },

});

function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/find-auth" element={<FindCredential />} />
              <Route path="retailers" element={<Retailers />} />

              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <MainLayout />
                  </ProtectedRoute>
                }
              >
                <Route index element={<OrderHome />} />
                <Route path="products" element={<Products />} />
                <Route path="products/:id" element={<ProductDetail />} />
                <Route path="manual-order" element={<ManualOrder />} />
                <Route path="order-history" element={<OrderHistory />} />
                <Route path="shopping-cart" element={<ShoppingCart />} />
              </Route>

              <Route path="*" element={<NoMatch />} />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
