import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useId, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import RISOLogo from './../../assets/logo_with_name_b.png';
import { RetailerSelect } from '../retailer/components/RetailerSelect';
import { createPublicAxios } from '../../hooks';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="http://risokorea.co.kr/">
        RISO Korea LTD.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

type Retailer = {
  id: string;
  name: string;
};

export const Signup = () => {
  const id = useId();

  const navigate = useNavigate();
  // const [credential, setCredential] = useState<SignupCredential>(defaultSignupCredential);
  const [currentRetailer, setCurrentRetailer] = useState<Retailer | null>(null);
  const axios = createPublicAxios();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const submitData = Object.fromEntries(data);

    if (submitData['password'] !== submitData['password-confirm']) {
      console.error('패스워드와 패스워드 확인값이 일치하지 않습니다.');
      return;
    }

    if (currentRetailer === null) {
      console.error('소속 대리점 항목을 선택하지 않았습니다.');
    }

    delete submitData['password-confirm'];

    const result = await axios.post('/auth/signup', {
      ...submitData,
      retailerId: currentRetailer!.id,
    });

    if (result.status === 201) {
      localStorage.setItem('accessToken', result.data.accessToken);
      localStorage.setItem('refreshToken', result.data.refreshToken);
      navigate('/');
    }
  };

  const onRetailerChanged = (newRetailer: Retailer | null) => {
    console.log(`onRetailerChanged from ${currentRetailer?.name} to ${newRetailer?.name}`);
    setCurrentRetailer(newRetailer);
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: '11rem' }} component="img" src={RISOLogo} alt="RISO Logo" />
        <Typography component="h4" variant="h4" sx={{ mt: 3, mb: 1 }}>
          회원 가입
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            {/* 이메일 주소 */}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id={`${id}:email`}
                label="이메일 주소"
                name="email"
                autoComplete="email"
                autoFocus
              />
            </Grid>
            {/* 패스워드 */}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="암호"
                type="password"
                id={`${id}:password`}
              />
            </Grid>
            {/* 패스워드 확인 */}
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password-confirm"
                label="암호 확인"
                type="password"
                id={`${id}:password-confirm`}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="name"
                name="name"
                required
                fullWidth
                id={`${id}:name`}
                label="사용자 이름"
              />
            </Grid>
            <Grid item xs={12}>
              <RetailerSelect onSelectionChanged={onRetailerChanged} />
            </Grid>
            {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid> */}
          </Grid>
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 4, mb: 2 }}>
            회원 가입하기
          </Button>
          <Grid container justifyContent="center" mt={'0.6rem'}>
            <Grid item>
              {/* <Link href="#" variant="body2"> */}
              <RouterLink to={'/login'} replace={true}>
                <Typography variant="body2">이미 가입하셨나요? 로그인하러 가기</Typography>
              </RouterLink>
              {/* </Link> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ position: 'fixed', width: '100%', left: 0, bottom: '1.5rem' }} />
    </Container>
  );
};
