import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useAuth } from '../../common/contexts/AuthProvider';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import RISOLogo from './../../assets/logo_with_name_b.png';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="http://risokorea.co.kr/">
        RISO Korea LTD.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export const Login = () => {
  const { isAuthenticated, login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const credential = {
      email: data.get('email') as string,
      password: data.get('password') as string,
    };

    if (await login(credential)) {
      navigate('/');
    } else {
      alert('로그인 실패');
    }
  };

  useLayoutEffect(() => {
    if (isAuthenticated) {
      // return <Navigate to="/" replace={true} />;
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: '12rem' }} component="img" src={RISOLogo} alt="RISO Logo" />
        <Typography component="h4" variant="h4" sx={{ mt: 3, mb: 5 }}>
          로그인
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="이메일 주소"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="암호"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="내 아이디(이메일) 기억하기"
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 3 }}>
            로그인 하기
          </Button>
          <Grid container>
            <Grid item xs sx={{ display: 'flex', justifyContent: 'center' }}>
              <RouterLink to={'/find-auth'} replace={true}>
                <Typography variant="body2">아이디/비밀번호 찾기</Typography>
              </RouterLink>
            </Grid>
            <Grid item xs sx={{ display: 'flex', justifyContent: 'center' }}>
              <RouterLink to={'/signup'} replace={true}>
                <Typography variant="body2">회원 가입</Typography>
              </RouterLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ position: 'fixed', width: '100%', left: 0, bottom: '1.5rem' }} />
    </Container>
  );
};
