import React, { FC } from 'react';
import ImageIcon from '@mui/icons-material/Image';
import { Grid, IconButton, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { CartItem } from '../contexts/CartProvider';

// export type CartItem = {
//   id: number;
//   orderType: 'normal' | 'manual';
//   name: string;
//   qty: number;
// };

type ShoppingCartItemProps = {
  item: CartItem;
  onRemoveClicked: (id: number) => void;
};

export const ShoppingCartItem: FC<ShoppingCartItemProps> = ({ item, onRemoveClicked }) => {
  const { id, orderType, name, qty } = item;

  return (
    <Grid container sx={{ flexGrow: 1, minHeight: '3rem' }} display={'flex'} alignItems={'center'}>
      <Grid item xs={1} display={'flex'} alignItems={'center'}>
        {orderType === 'manual' ? (
          <EditOutlinedIcon fontSize="large" />
        ) : (
          <ImageIcon fontSize="large" />
        )}
      </Grid>
      <Grid item xs={8} paddingLeft={'1rem'}>
        <Typography>{name}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography>{qty}개</Typography>
      </Grid>
      <Grid item xs={1} display={'flex'} alignItems={'center'}>
        <IconButton aria-label="delete" onClick={() => onRemoveClicked(id)}>
          <DeleteOutlineOutlinedIcon color="error" />
        </IconButton>
      </Grid>
    </Grid>
  );
};
