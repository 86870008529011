import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPublicAxios } from '../../hooks/useAxios';
import { useNavigate } from 'react-router-dom';

interface LoginCredential {
  email: string;
  password: string;
}

interface LoginResponse {
  accessToken: string;
  refreshToken: string;
}

interface AuthContextType {
  login: (credential: LoginCredential) => Promise<boolean>;
  logout: () => void;
  isAuthenticated: boolean;
  accessToken: string | null;
}
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);

  // console.log('AuthContext:: ', context);
  if (!context) {
    throw new Error('useAuth는 AuthProvider 내부에서 사용되어야 합니다.');
  }

  return context;
};

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  // used useRef to avoid re-rendering... 
  // ref: https://github.com/remix-run/react-router/issues/7634#issuecomment-2184999343
  const navigate = useRef(useNavigate());
  const axiosInstance = useMemo(() => createPublicAxios(), []);

  useEffect(() => {
    const accessTokenFromStorage = localStorage.getItem('accessToken');

    if (accessTokenFromStorage) {
      setAccessToken(accessTokenFromStorage);
      setIsAuthenticated(true);
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessTokenFromStorage}`;
      return;
    }
  }, [axiosInstance]);

  const login = useCallback(
    async ({ email, password }: LoginCredential): Promise<boolean> => {
      try {
        const response = await axiosInstance.post<LoginResponse>('/auth/login', {
          email,
          password,
        });
        const { accessToken, refreshToken } = response.data;

        // FIXME! js-cookie를 사용하도록 변경할 것
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);

        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        setIsAuthenticated(true);
        setAccessToken(accessToken);
        navigate.current('/');
        return true;
      } catch (error) {
        console.error('error occurred during login', error);
        throw new Error('failed to login');
      }
    },
    [navigate, axiosInstance]
  );

  const logout = useCallback(() => {
    // FIXME! js-cookie를 사용하도록 변경할 것
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    setIsAuthenticated(false);
    setAccessToken(null);

    delete axiosInstance.defaults.headers.common['Authorization'];
    navigate.current('/login');
  }, [navigate, axiosInstance]);

  return (
    <AuthContext.Provider value={{ login, logout, isAuthenticated, accessToken }}>
      {children}
    </AuthContext.Provider>
  );
};
