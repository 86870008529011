import { ReactNode, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';

import logo from '../../assets/logo.png';
import { useAuth } from '../contexts/AuthProvider';
import { Divider, Drawer, ListItem, ListItemButton, ListItemText } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MiscellaneousServicesOutlinedIcon from '@mui/icons-material/MiscellaneousServicesOutlined';
import EmojiTransportationOutlinedIcon from '@mui/icons-material/EmojiTransportationOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import LogoutIcon from '@mui/icons-material/Logout';

type Page = {
  title: string;
  route: string;
  icon?: ReactNode;
};

const navPages: Page[] = [
  {
    title: 'Home',
    route: '/',
    icon: <HomeOutlinedIcon />,
  },
  {
    title: 'Products',
    route: '/products',
    icon: <MiscellaneousServicesOutlinedIcon />,
  },
  {
    title: 'Retailers',
    route: '/retailers',
    icon: <EmojiTransportationOutlinedIcon />,
  },
  {
    title: 'No Match',
    route: '/fasdfa',
    icon: <ErrorOutlineOutlinedIcon />,
  },
];

type SettingType = {
  title: string;
  onClick?: () => void;
};

const settings: SettingType[] = [
  {
    title: 'Profile',
  },
  { title: 'Account' },
  { title: 'Dashboard' },
  {
    title: 'Logout',
    onClick: () => {
      console.log('logout');
    },
  },
];

export const Header = () => {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const { logout } = useAuth();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (setting: SettingType) => {
    logout();
    setAnchorElUser(null);
  };

  const goToPage = (page: Page) => {
    toggleDrawer(false);
    navigate(page.route);
  };

  const toggleDrawer = (newOpen: boolean) => {
    setIsDrawerOpened(newOpen);
  };

  return (
    <div>
      <AppBar position="fixed">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              component="img"
              src={logo}
              alt="logo"
              sx={{
                display: { xs: 'none', md: 'flex' },
                width: '30px',
                height: 'auto',
                mr: 1,
              }}
            />
            <Typography
              variant="h6"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'S-CoreDream-5Medium',
                letterSpacing: '.1rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              리소코리아
            </Typography>

            {/* 모바일 환경 메뉴 */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => toggleDrawer(true)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                open={isDrawerOpened}
                onClose={() => toggleDrawer(false)}
                sx={{
                  '& .MuiDrawer-paper': {
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingY: '0.5rem',
                  },
                }}
              >
                <Box
                  sx={{
                    background: 'yellow',
                    minHeight: '200px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  사용자 정보 영역
                </Box>
                <Box sx={{}}>
                  {navPages.map((page) => (
                    <ListItem key={page.title} disablePadding onClick={() => goToPage(page)}>
                      <ListItemButton>
                        <ListItemIcon sx={{ minWidth: '36px' }}>{page.icon}</ListItemIcon>
                        <ListItemText primary={page.title} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </Box>
                <Box>
                  <Divider />
                  <Box display={'flex'} justifyContent={'center'}>
                    <Button endIcon={<LogoutIcon />} onClick={logout}>
                      로그아웃
                    </Button>
                  </Box>
                </Box>
              </Drawer>
            </Box>

            {/* 데스크톱 환경 메뉴 */}
            <Box
              component="img"
              src={logo}
              alt="logo"
              sx={{
                display: { xs: 'flex', md: 'none' },
                width: '30px',
                height: 'auto',
                mr: 1,
              }}
            />
            <Typography
              variant="h5"
              noWrap
              component={Link}
              to="/"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'S-CoreDream-5Medium',
                letterSpacing: '.1rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              리소코리아
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {navPages.map((page) => (
                <Button
                  key={page.title}
                  onClick={() => goToPage(page)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.title} LG
                </Button>
              ))}
            </Box>

            {/* 설정 메뉴 */}
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting.title} onClick={() => handleCloseUserMenu(setting)}>
                    <Typography textAlign="center">{setting.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};
