import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { Fragment } from 'react/jsx-runtime';

type ProductItemProps = {
  name: string;
  image: string;
  description: string;
};

export const ProductItem: FC<ProductItemProps> = ({ name, image, description }) => {
  return (
    <>
      <ListItem alignItems="center">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={image} />
        </ListItemAvatar>
        <ListItemText
          primary={<Typography variant='body1' fontFamily={'S-CoreDream-5Medium'}>
            {name}
          </Typography>}
          secondaryTypographyProps={{
            component: 'div',
          }}
          secondary={
            <Fragment>
              <Typography
              fontSize={'0.8rem'}
                sx={{ display: 'block' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                GD, GL 호환
              </Typography>
              <Typography variant='body2'>
                {description}
              </Typography>
            </Fragment>
          }
        />
      </ListItem>
    </>
  );
};
