import {
  Badge,
  BadgeProps,
  BottomNavigation,
  BottomNavigationAction,
  BottomNavigationActionProps,
  Paper,
  styled,
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useCart } from '../contexts/CartProvider';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 12,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const StyledBottomNavAction = styled(BottomNavigationAction)<BottomNavigationActionProps>(
  ({ theme }) => ({
    '& .MuiBottomNavigationAction-label': {
      fontSize: '0.7rem',
      '&.Mui-selected': {
        fontWeight: 'bold',
      },
    },
  })
);

export const BottomNav = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const { cartItems } = useCart();

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <StyledBottomNavAction
          label="제품검색"
          icon={<SearchOutlinedIcon />}
          onClick={() => navigate('/products')}
        />
        <StyledBottomNavAction
          label="수기주문"
          icon={<EditOutlinedIcon />}
          onClick={() => navigate('/manual-order')}
        />
        <StyledBottomNavAction
          label="주문대기목록"
          icon={
            <StyledBadge badgeContent={cartItems.length} color="primary">
              <ShoppingCartOutlinedIcon color="action" />
            </StyledBadge>
          }
          onClick={() => navigate('/shopping-cart')}
        />
        <StyledBottomNavAction
          label="지난주문내역"
          icon={<HistoryOutlinedIcon />}
          onClick={() => navigate('/order-history')}
        />
      </BottomNavigation>
    </Paper>
  );
};
