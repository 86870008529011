import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { FC, Fragment, useEffect, useState } from 'react';
import { createPublicAxios } from '../../../hooks';

type Retailer = {
  id: string;
  name: string;
};

type RetailerSelectProps = {
  onSelectionChanged?: (value: Retailer | null) => void;
};

export const RetailerSelect: FC<RetailerSelectProps> = ({ onSelectionChanged }) => {
  const axios = createPublicAxios();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly Retailer[]>([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (options.length > 0) {
        return;
      }

      const retailers = (await axios.get<Retailer[]>('/retailers/names')).data;

      if (active) {
        setOptions([...retailers]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, axios, options]);

  // useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      // sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(_e, value) => {
        if (onSelectionChanged) return onSelectionChanged(value);
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label="소속 대리점 비동기"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};
