import React, { useEffect, useState } from 'react';
import { OrderedItem, OrderHistoryItem } from './components/OrderHistoryItem';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

const fakeItems : OrderedItem[]= [
  {
    date: new Date(),
    items: [
      {
        name: '배판롤러',
        qty: 3,
      },
      {
        name: 'ComColor Y 잉크 500m',
        qty: 1,
      },
      {
        name: 'RG 사바키',
        qty: 2,
      },
    ],
    status: 'pending' as 'pending' | 'completed' | 'canceled',
    delivery: '경동택배 리소코리아점',
  },
  {
    date: new Date(),
    items: [
      {
        name: '급지롤러',
        qty: 3,
      },
      {
        name: 'ComColor M 잉크 1000m',
        qty: 1,
      },
      {
        name: 'RG 사바키',
        qty: 2,
      },
      {
        name: 'MiScreen a4',
        qty: 2,
      },
    ],
    status: 'canceled' as 'pending' | 'completed' | 'canceled',
    delivery: '경동택배 리소코리아점',
  },
  {
    date: new Date(),
    items: [
      {
        name: '배판롤러',
        qty: 3,
      },
      {
        name: 'ComColor Y 잉크 500m',
        qty: 1,
      },
      {
        name: 'RG 사바키',
        qty: 2,
      },
    ],
    status: 'pending' as 'pending' | 'completed' | 'canceled',
    delivery: '경동택배 리소코리아점',
  },
  {
    date: new Date(),
    items: [
      {
        name: '급지롤러',
        qty: 3,
      },
      {
        name: 'ComColor M 잉크 1000m',
        qty: 1,
      },
      {
        name: 'RG 사바키',
        qty: 2,
      },
      {
        name: 'MiScreen a4',
        qty: 2,
      },
    ],
    status: 'canceled' as 'pending' | 'completed' | 'canceled',
    delivery: '경동택배 리소코리아점',
  },
  {
    date: new Date(),
    items: [
      {
        name: '배판롤러',
        qty: 3,
      },
      {
        name: 'ComColor Y 잉크 500m',
        qty: 1,
      },
      {
        name: 'RG 사바키',
        qty: 2,
      },
    ],
    status: 'pending' as 'pending' | 'completed' | 'canceled',
    delivery: '경동택배 리소코리아점',
  },
  {
    date: new Date(),
    items: [
      {
        name: '급지롤러',
        qty: 3,
      },
      {
        name: 'ComColor M 잉크 1000m',
        qty: 1,
      },
      {
        name: 'RG 사바키',
        qty: 2,
      },
      {
        name: 'MiScreen a4',
        qty: 2,
      },
    ],
    status: 'canceled' as 'pending' | 'completed' | 'canceled',
    delivery: '경동택배 리소코리아점',
  },
  {
    date: new Date(),
    items: [
      {
        name: '배판롤러',
        qty: 3,
      },
      {
        name: 'ComColor Y 잉크 500m',
        qty: 1,
      },
      {
        name: 'RG 사바키',
        qty: 2,
      },
    ],
    status: 'pending' as 'pending' | 'completed' | 'canceled',
    delivery: '경동택배 리소코리아점',
  },
  {
    date: new Date(),
    items: [
      {
        name: '급지롤러',
        qty: 3,
      },
      {
        name: 'ComColor M 잉크 1000m',
        qty: 1,
      },
      {
        name: 'RG 사바키',
        qty: 2,
      },
      {
        name: 'MiScreen a4',
        qty: 2,
      },
    ],
    status: 'canceled' as 'pending' | 'completed' | 'canceled',
    delivery: '경동택배 리소코리아점',
  },
];

export const OrderHistory = () => {
  const [history, setHistory] = useState<OrderedItem[]>([]);
  const [age, setAge] = React.useState('');
  const [currentExpanded, setCurrentExpanded] = useState<string | null>(null)

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  useEffect(() => {
    setHistory(fakeItems);
  }, []);

  const onItemExpanded = (id: string) => {
    setCurrentExpanded(id);
  }

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label">범위</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={age}
          label="Age"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>내주문만</MenuItem>
          <MenuItem value={20}>대리점전체</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small-label">기간</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={age}
          label="Age"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>1개월이내</MenuItem>
          <MenuItem value={20}>3개월이내</MenuItem>
          <MenuItem value={30}>전체</MenuItem>
        </Select>
      </FormControl>
      {history.map((h, idx) => (
        <OrderHistoryItem key={idx} id={idx.toString()} item={h} onItemExpanded={onItemExpanded} currentOpened={currentExpanded} />
      ))}
    </>
  );
};
