import { useEffect, useState } from 'react';
import { useAxios } from '../../hooks';

export const Retailers = () => {
  const [retailers, setRetailers] = useState<string>('');
  const axios = useAxios();

  useEffect(() => {
    const getRetailers = async ()=>{
      const retailers = await axios.get('/retailers');
      const retailersStr = JSON.stringify(retailers.data, null, 2);
      setRetailers(retailersStr);
      console.log(retailersStr);
    }

    getRetailers();
  }, []);

  return <div>{retailers}</div>;
};
