import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductItem } from './components/ProductItem';
import { ItemSearchBar } from './components/ItemSearchBar';
import { Box, Divider, List, ListItemButton } from '@mui/material';
import { useAxios } from '../../hooks';

export type ProductType = {
  id: string;
  name: string;
  productType: string;
  images: string[];
  description: string;
};

export const Products = () => {
  const navigate = useNavigate();
  const axios = useAxios();
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [searchResult, setSearchResult] = useState<ProductType[]>([]);

  const onItemClicked = (id: string) => {
    navigate(`/products/${id}`);
  };

  useEffect(() => {
    const getItems = async () => {
      const result = await axios.get('/products');
      setSearchResult(result.data);
      console.log(result.data);
    };

    getItems();
  }, []);

  useEffect(() => {
    setSearchResult(searchResult.filter((item) => item.name.includes(searchKeyword)));
  }, [searchKeyword]);

  return (
    <>
      <ItemSearchBar setSearchKeyword={setSearchKeyword} />
      <Box sx={{ height: `calc(100vh - 11.5rem)`, overflow: 'scroll' }}>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {searchResult.map((item, idx) => (
            <>
            <ListItemButton sx={{padding: 0}} disableGutters key={idx} onClick={() => onItemClicked(item.id)}>
              <ProductItem
                name={item.name}
                image={item.images.length > 0 ? item.images[0] : ''}
                description={item.description}
              />
            </ListItemButton>
            <Divider/>

            </>
          ))}
        </List>
      </Box>
    </>
  );
};
