import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { CartItem, useCart } from './contexts/CartProvider';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const ManualOrder = () => {
  const [name, setName] = useState<string>('');
  const [qty, setQty] = useState<number>(1);
  const [description, setDescription] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { addItem } = useCart();

  const openDialog = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);
  const goToShoppingCartPage = () => navigate('/shopping-cart');
  const continueManualOrder = () => closeDialog();

  const onQtyChanged = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    /**referred https://velog.io/@yyeonggg/%EC%97%B4%EB%B0%9B%EB%8A%94-MUI-%EC%A0%95%EB%A6%AC */
    setQty(+e.target.value.replace(/\D/g, ''));
  };

  const addItemToCart = () => {
    /* validate inputs*/

    const newItem: Omit<CartItem, 'id'> = {
      orderType: 'manual',
      name,
      qty,
      description,
    };

    addItem(newItem);

    /** reset values */
    setName('');
    setQty(1);
    setDescription('');

    openDialog();
  };

  return (
    <>
      <Box sx={{ height: `calc(100vh - 11rem)`, overflow: 'scroll' }}>
        
        {/* 수기 주문 안내 */}
        <Card variant="outlined" sx={{ height: 120, my: '0.5rem' }}>
          <CardContent>
            <Typography variant="h5" component={'h2'}>
              주문 품목 직접 입력
            </Typography>
            <Typography variant="body2">
              주문 품목이 목록에 없는 경우, 직접 품목 정보를 입력하여 주문을 신청할 수 있습니다.
              모델명, 부품명, 수량을 입력하여 주십시오.
            </Typography>
          </CardContent>
        </Card>

        {/* 관련 모델명 */}
        <FormControl sx={{ my: 1, minWidth: 300 }} fullWidth>
          <InputLabel id="demo-simple-select-helper-label">관련모델명</InputLabel>
          <Select
            required
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            // value={age}
            label="Age"
            // onChange={handleChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
          <FormHelperText>주문상품과 관련있는 모델명을 선택합니다.</FormHelperText>
        </FormControl>

        {/* 주문 항목 */}
        <TextField
          required
          fullWidth
          label="주문 항목"
          sx={{ my: 1 }}
          margin="none"
          error={name.length > 30}
          helperText={
            name.length > 30
              ? '주문 항목은 30자를 넘을 수 없습니다. 필요한 경우 기타 참고 사항에 기입하십시오'
              : '주문 항목을 기입합니다'
          }
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        {/* 주문 수량*/}
        <TextField
          required
          error={qty <= 0}
          sx={{ my: 1 }}
          fullWidth
          label="주문 수량"
          margin="none"
          helperText={
            qty <= 0 ? '주문 수량은 한개 이상이어야 합니다' : '주문수량을 입력하세요 (최대 99개)'
          }
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            min: '1',
            maxLength: 2,
          }}
          value={qty}
          onChange={onQtyChanged}
        />

        <TextField
          id="outlined-multiline-flexible"
          label="기타 참고 사항"
          sx={{ my: 1 }}
          fullWidth
          multiline
          minRows={3}
          maxRows={4}
          error={description.length > 255}
          helperText={
            description.length > 255
              ? '255자를 넘을 수 없습니다'
              : '상품 관련 참고할 사항을 입력합니다.'
          }
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Box>

      <Box
        sx={{
          position: 'fixed',
          width: '100%',
          left: 0,
          bottom: '4.5rem',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="contained"
          sx={{ width: '91%' }}
          disabled={qty <= 0 || name.trim() === '' || name.length > 30 || description.length > 255}
          onClick={addItemToCart}
        >
          주문대기목록에 추가
        </Button>
      </Box>

      {/* dialog for confirming order*/}
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">제품이 주문대기목록에 추가되었습니다.</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            항목 표시
          </DialogContentText> */}
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Button variant="outlined" onClick={continueManualOrder}>
            계속 수기 주문
          </Button>
          <Button variant="outlined" onClick={goToShoppingCartPage} autoFocus>
            주문대기목록 이동
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
