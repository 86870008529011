import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';

interface CartContextType {
  cartItems: CartItem[];
  addItem: (cartItem: Omit<CartItem, 'id'>) => void;
  removeItem: (itemId: number) => void;
}

export type CartItem = {
  id: number;
  orderType: 'manual' | 'search';
  name: string;
  qty: number;
  description?: string /* 수기주문 기타 참고사항 */;
};

// const defaultContextValue: CartContextType = {
//   cartItems: [],
//   addItem: (cartItem) => {},
//   removeItem: (id) => {},
// };

const CartContext = createContext<CartContextType | undefined>(undefined);

export const useCart = () => {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error('useCart는 CartProvider 내부에서 사용되어야 합니다.');
  }

  return context;
};

export const CartProvider: FC<PropsWithChildren> = ({ children }) => {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);

  const addItem = (cartItem: Omit<CartItem, 'id'>) => {
    const newId = Date.now();
    setCartItems((prev) => [...prev, { ...cartItem, id: newId }]);
  };

  const removeItem = (itemId: number) => {
    const newItems = cartItems.filter((item) => item.id !== itemId);
    setCartItems(newItems);
  };

  useEffect(() => {
    // TODO. cookie 같은 것으로 영속화된 정보를 load
  }, []);

  return (
    <CartContext.Provider value={{ cartItems, addItem, removeItem }}>
      {children}
    </CartContext.Provider>
  );
};
