import { FC, PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';
import { useAuth } from '../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';

export const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useRef(useNavigate());
  const [myChildren, setMyChildren] = useState<ReactNode>(null);
  console.log('ProtectedRoute');

  useEffect(() => {
    isAuthenticated ? setMyChildren(children) : navigate.current('/login');
  }, [children, isAuthenticated]);

  return <>{myChildren}</>;
};
