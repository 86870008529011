import React, { SyntheticEvent } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

type Item = {
  name: string;
  qty: number;
};

export type OrderedItem = {
  date: Date;
  items: Item[];
  status: 'pending' | 'completed' | 'canceled';
  delivery: string;
};

type OrderHistoryItemProps = {
  id: string;
  item: OrderedItem;
  onItemExpanded: (id: string) => void;
  currentOpened: string | null /* 현재 열려있는 형제 Accordion의 id  */;
};

export const OrderHistoryItem: React.FC<OrderHistoryItemProps> = ({
  id,
  item,
  onItemExpanded,
  currentOpened,
}) => {
  const { date, items, status, delivery } = item;

  const itemsStr = items.length === 1 ? items[0].name : `${items[0].name} 외 ${items.length - 1}건`;

  const onChange = (e: SyntheticEvent, expanded: boolean) => {
    if (expanded) {
      onItemExpanded(id);
    }
  };

  return (
    <>
      <Accordion onChange={onChange} expanded={id === currentOpened}>
        <AccordionSummary expandIcon={<ArrowDownwardIcon />} aria-controls="panel1-content">
          <div>
            <Typography variant="body1">
              <b>{date.toLocaleDateString()}</b>
            </Typography>
            <Typography variant="subtitle1" component={'p'}>
              {itemsStr}, {status}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {items.map((item, idx) => (
            <Typography key={idx}>
              {item.name}: {item.qty}개
            </Typography>
          ))}
          <Typography>배송지: {delivery}</Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
